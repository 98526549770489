.modal {
    opacity: 0;
    position: fixed;
    z-index: 2000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, .5);
    pointer-events: none;
    transition: opacity .4s ease-in-out;

    &.show {
        opacity: 1;
        pointer-events: auto;
    }

    &__content {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid rgba(0, 0, 0, .2);
        color: $dark;
        margin: 5rem auto;
        width: 40%;
        box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
        border-radius: 5px;
        transform: translateY(-40px);
        transition: transform .3s ease-out;
        z-index: 2001;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #dee2e6;

        &-title {
            margin-bottom: 0;
        }

        &-close {
            color: gainsboro;
            font-size: 3rem;
            line-height: 0;

            &:hover,
            &:focus {
                color: $dark;
                cursor: pointer;
            }
        }
    }

    &__footer {
        border-top: 1px solid #dee2e6;
        display: flex;
        flex-wrap: wrap;
        flex-shrink: 0;
        align-items: center;
        justify-content: flex-end;

        button {
            margin: 0;
        }
    }
}


.modal.show .modal__content {
    transform: none;
}

// Disable body scrolling
.no-scroll {
    overflow: hidden;
}


// Modal header, body and footer
.modal__header,
.modal__body,
.modal__footer {
    padding: 1.5rem 3rem;
}


@media only screen and (max-width : $breakpoint-lg) {
    .modal__content {
        width: 70% !important;
        margin: 3rem auto;
    }

    // Modal header, body and footer
    .modal__header,
    .modal__body,
    .modal__footer {
        padding: 1.2rem 1.5rem;
    }
}

@media only screen and (max-width: $breakpoint-sm) {
    .modal__content {
        width: 90% !important;
        margin: 3rem auto;
    }
}