.upload-list {
    margin-top: 3rem;
    margin-bottom: 2rem;

    &-item {
        margin: 1rem 0;
        padding: .7rem 2rem;
        background-color: #F6F7F8;
        color: $grey-dark;
        border-radius: 5px;
        font-size: 1.5rem;
        text-align: center;
        font-weight: bold;
        cursor: pointer;

        &.active {
            background-color: $grey-dark;
            color: #fff;
        }
    }
}