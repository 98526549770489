// Manage bookmarks form
.modal-form {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2.5rem;

    &__input {
        margin-left: 2rem;

        &--bookmarks {
            margin-right: .8rem;
        }
    }

    // Icon selection
    &__icons {
        width: 85%;
        margin-top: 2rem;
        margin-left: 4.5rem;
        display: flex;
        flex-wrap: wrap;

        span {
            margin-bottom: 1.5rem;
            margin-right: 2.5rem;
        }

        &>div {
            cursor: pointer;
        }
    }

    // Close bookmark form
    &__close {
        cursor: pointer;
    }

    // Save bookmark button
    &__button {
        background-color: $secondary;
        text-align: center;
        border-color: $secondary;
        color: #fff;
        cursor: text;
        border-radius: 3px;
        border-width: 1px;
        border-style: solid;
        box-sizing: border-box;
        min-width: 7rem;
        font-size: 14px;
        transition: background-color 0.2s ease-in-out 0s, border-color 0.2s ease-in-out 0s;
        height: 3.8rem;
    }

    // Add bookmark button
    &__add-btn {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: grey;
        font-family: 'Lato';
        font-weight: 500;
        font-size: 1.5rem;
        letter-spacing: .8px;
        width: 19rem;
        margin-top: 3rem;
        margin-bottom: 2rem;

        p {
            margin-left: 3px;
        }
    }

    // Bottom submit button
    &__submit-btn {
        margin-top: auto;
    }

}