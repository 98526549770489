.searchbar {
    width: 350px;
    background-color: #EFEFEF;
    padding: 10px;
    border: none;
    cursor: text;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;

    &__icon {
        color: #8e8e8e;
        margin-right: 1rem;
    }

    &__close {
        margin-right: 0;
        cursor: pointer;
        color: #8e8e8e;
    }

    &__input {
        background-color: transparent;
        padding-top: 10px;
        padding-bottom: 10px;
        width: 90%;
        border: none;
        color: #262626;
        font-size: 16px;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";;


        &::placeholder {
            color: #8e8e8e;
            font-weight: 300;
        }

        &:focus {
            outline: none;
        }
    }
}