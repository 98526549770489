.bottom-nav {
    display: none;
    position: fixed;
    bottom: 0;
    // height: 5rem;
    box-shadow: 0 0 3px rgba(0, 0, 0, .2);
    background-color: #fff;
    overflow-x: auto;
    width: 100%;
    z-index: 100;
    padding-bottom: 2rem;
    padding-top: 1rem;

    &-link {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 3px;
        flex-grow: 1; 
        min-width: 5rem;
        overflow: hidden;
        white-space: nowrap;
        font-size: 1.3rem;
        color: #444444;
        transition: background-color 0.1s ease-in-out;

        & > span {
            margin-bottom: 3px;
        }
    }
}


@media only screen and (max-width : $breakpoint-lg) { 
    body {
        margin-bottom: 5.5rem;
    }

    .bottom-nav {
        display: flex;
    }
}