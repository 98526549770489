*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}


html {
    font-size: 62.5%;
    font-family: sans-serif;
    line-height: 1.15;
}

body {
    margin: 0;
    font-family: -apple-system, "Lato", Arial, sans-serif;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
}

    
a {
    text-decoration: none;
}

li {
    list-style-type:none;
}

button {
    border: none;
    display: inline-block;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    background-color: transparent;
}

button:hover {
    cursor: pointer;
    outline: none;
}

button:active, 
button:focus {
    outline: none;
}

input {
    font-family: inherit;
    color: inherit;
}
