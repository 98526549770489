.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    z-index: 100;
    width: 22rem;
}

.form-heading {
    font-size: 1.6rem;
    font-weight: 700;
    color: $grey-black;
    margin-bottom: 3rem;
    text-align: center;
}

form {
    width: 100%;

    & .invisible {
        display: none;
    }

    & button {
        width: 100%;
        border: none;
        background-color: $primary;
        color: white;
        font-weight: 700;
        padding: 1.3rem;
        border-radius: 9px;
        margin-top: 1.5rem;
        border: 2px solid white;
        font-size: 1.5rem;

        &:hover {
            cursor: pointer;
        }

        &:active,
        &:focus {
            outline: none;
            border: 2px solid mix(white, $primary, 55%);
        }
    }


    input[type=text],
    input[type=password],
    textarea {
        font-size: 1.4rem;
        width: 100%;
        font-family: inherit;
        margin-bottom: 2.5rem;
        border: 1px solid #dddddd;
        padding: 1.1rem 1.5rem;
        border-radius: 5px;
        color: $grey-black;
        -webkit-appearance: none;

        &:focus {
            outline: none;
            border: 1px solid #d1d0d0;
        }

        &::placeholder {
            color: #d1cfcf;
        }

        // Validation
        &.invalid {
            border-color: #dd3444;

            &:focus,
            &:active {
                box-shadow: 0 0 0 3px rgba(221, 52, 68, 0.25);
            }
        }
    }

    small {
        position: relative;
        top: -25px;
        left: 5px;
        color: $grey-dark;
    }

    .invalid {
        color: #DD3444;
    }

    .add-textarea {
        margin-top: 20px;
        border: 1px dashed #dddddd;
        border-radius: 5px;
        padding: 7px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all .2s;
        color: #dddddd;
        margin-bottom: 10px;

        &:hover {
            color: $grey-dark;
            border-color: $grey-dark;
        }

        span {
            margin-left: 5px;
            font-size: 13px;
        }
    }

    textarea {
        margin-bottom: 15px !important;
        resize: none;
    }
}




// Desktop
@media only screen and (min-width : $desktop) {

    .form-container {
        &.half {
            width: 29rem;
        }

        &.full {
            width: 22rem;
        }
    }

    form {

        input[type=text],
        input[type=password],
        textarea {
            padding: .7rem 1.5rem;
            margin-bottom: 2rem;
            font-size: 1.5rem;
        }

        button {
            margin-top: 1.2rem;
            padding: 1.2rem;
        }
    }

}




/* ===================================
   Rounded input
==================================== */

.rounded-input {
    -webkit-appearance: none;
    border: none;
    box-shadow: 0 0px 7px rgba(40, 75, 124, .15);
    font-family: inherit;
    padding: 1rem 1.7rem;
    font-size: 1.6rem;
    padding-left: 4.8rem;
    border-radius: 50px;
    color: $grey-black;

    &::placeholder {
        color: $grey-light;
    }
}

.input-icon {
    position: relative;
    top: 3rem;
    left: 2rem;
    color: $grey-medium;
}

.rounded-input:focus,
button {
    outline: none;
}


// Desktop
@media only screen and (min-width : $desktop) {

    .rounded-input {
        border: none;
        padding: 1rem 1.2rem;
        padding-left: 3.7rem;
        font-size: 1.4rem;
    }

    .input-icon {
        top: 0px;
        left: 2.9rem;
        font-size: 1.4rem;
    }
}


/* ===================================
   Input text
==================================== */

.input-text {
    font-family: inherit;
    border: none;
    background-color: transparent;
    color: #eebf3d;
    font-weight: 600;
    font-size: 2.4rem;
    margin-right: 2rem;
    -webkit-appearance: none;

    &:hover {
        cursor: pointer;
    }

    &:focus {
        outline: none;
        border: none;
    }
}