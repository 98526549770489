
/* ===================================
   Stitched Card
==================================== */

.stitched-card {
    position: relative;
    background-color: #fff;
    padding: 7rem 6rem;
    margin-top: 2rem;
    max-height: 60rem;
    overflow-y: scroll;
}


// Desktop
@media only screen and (min-width : $desktop) { 
    .stitched-card {
        border: 2px dashed $secondary;
        padding: 7rem 6rem;
        border-radius: 7px;
    }
}


/* ===================================
   Auth Card
==================================== */
.auth-card {
    border: 2px dashed $secondary;
    position: relative;
    background-color: #fff;
    width: 36rem;
    margin: 0 auto;
    text-align: center;
    top: 10vh;
    padding: 6rem 5rem;
    padding-bottom: 9rem;
    border-radius: 2rem;
    margin-top: 2rem;

    .form-heading {
        margin-bottom: 4rem;
    }

    .auth-icon {
        position: relative;
        height: 7rem;
        margin-bottom: 2rem;
        left: 2%;
    }
}


@media only screen and (max-width : $mobile) { 
    .auth-card {
        border: none;
        padding: 1rem 4rem;


        .auth-icon {
            height: 10rem;
        }
    }
}



