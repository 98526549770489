.loading-dots {
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading-dots > div {
    width: 1.2rem;
    height: 1.2rem;
    background-color: gainsboro;
    border-radius: 50%;
    display: inline-block;
    animation: 1.5s bounce infinite ease-in-out both;
    margin-left: 7px;
}

.loading-dots .bounce1 {
    animation-delay: 0s;
}

.loading-dots .bounce2 {
    animation-delay: 0.15s;
}

.loading-dots .bounce3 {
    animation-delay: 0.30s;
}


@keyframes bounce {
    0%, 80%, 100% {
        transform: scale(0);
    }

    40% {
        transform: scale(1);
    }
}