// Header
.header {
    z-index: 1000;
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    box-shadow: 0 2px 10px rgba(40, 75, 124, .08);
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #fff;
    min-height: 6.5rem;
    padding: 0 4.7rem;

    // Logo image and text
    &__logo {
        display: flex;

        img {
            height: 50px;
            margin-right: 7px;
        }

        h1 {
            position: relative;
            font-family: 'Lato';
            color: white;
            color: $grey-black;
            font-weight: 400;
            margin: auto;
            transition: all .5s;
            font-size: 3rem;
            vertical-align: middle;
            line-height: 0;
            transform: translateY(10%);
        }
    }
}


// Navigation
.nav {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--login {
        width: 100%;
    }

    &--logout {
        width: 88%;
    }

    &__link {
        color: $grey-black;
        font-size: 1.7rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .fa-sign-in-alt,
    .fa-user-plus {
        color: $primary;
        font-size: 2rem;
        margin-left: 1rem;
    }

    .fa-sign-in-alt {
        font-size: 2.2rem;
    }
}


/* ==========================================
    Mobile
========================================== */

@media only screen and (max-width : $mobile) {

    .header {
        justify-content: center;
        padding-left: 1rem;
        padding-right: 2rem;
        min-height: 5rem;

        &__logo {
            h1 {
                font-size: 2.2rem;
            }

            img {
                height: 3rem;
                margin-right: 4px;
            }
        }

        .nav {
            &--login {
                justify-content: center;
                transform: translateY(-4px);
            }

            &--logout {
                justify-content: space-between;
                width: 100%;
            }
        }
    }
}