// Center all ionicons
span.sc-bczRLJ {
    display: flex;
} 

/* ===================================
   Width
==================================== */

.w-100 {
  width: 100% !important;
}

/* ===================================
   Flex
==================================== */
.flex {
  display: flex;
}

.flex-center {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.flex-space-between {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.flex-space-around {
  display: flex !important;
  align-items: center !important;
  justify-content: space-around !important;
}

.flex-col {
  display: flex !important;
  flex-direction: column !important;
}

/* ===================================
   Hide elements
==================================== */

// Hide element
.hidden {
    display: none;
}

// Position: relative
.pos-relative {
    position: relative;
}

// Hide on Desktop
.hide-desktop {
    display: none !important;
}

// Margin left auto
.ml-auto {
    margin-left: auto;
}

// Margin right auto
.mr-auto {
    margin-right: auto;
}




@media only screen and (max-width : $mobile) { 
    // Hide on Mobile
    .hide-mobile {
        display: none !important;
    }

    .hide-desktop {
        display: block;
    }
}

/* ===================================
   Top
==================================== */
.top-1 {
    top: 1px !important;
}

.top-2 {
    top: 2px !important;
}

.top-3 {
    top: 3px !important;
}

.top-4 {
    top: 4px !important;
}

.top-5 {
    top: 5px !important;
}

.top-6 {
    top: 6px !important;
}

.top-7 {
    top: 7px !important;
}

.top-8 {
    top: 8px !important;
}

.top-8 {
    top: 8px !important;
}

.top-9 {
    top: 9px !important;
}

.top-10 {
    top: 10px !important;
}

.top-11 {
    top: 11px !important;
}

.top-12 {
    top: 12px !important;
}

.top-13 {
    top: 13px !important;
}

.top-14 {
    top: 14px !important;
}

.top-15 {
    top: 15px !important;
}


/* ===================================
   Margin
   m mt mb mx my 0 - 10
==================================== */


.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.5rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.5rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.5rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.5rem !important;
}

.m-2 {
  margin: 1rem !important;
}

.mt-2,
.my-2 {
  margin-top: 1rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 1rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 1rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 1rem !important;
}

.m-3 {
  margin: 1.5rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1.5rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1.5rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1.5rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1.5rem !important;
}

.m-4 {
  margin: 2rem !important;
}

.mt-4,
.my-4 {
  margin-top: 2rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 2rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 2rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 2rem !important;
}

.m-5 {
  margin: 2.5rem !important;
}

.mt-5,
.my-5 {
  margin-top: 2.5rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 2.5rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 2.5rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 2.5rem !important;
}

.m-6 {
    margin: 3rem !important;
  }
  

.mt-6,
.my-6 {
  margin-top: 3rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 3rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 3rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 3rem !important;
}

.m-7 {
    margin: 3.5rem !important;
}
  

.mt-7,
.my-7 {
  margin-top: 3.5rem !important;
}

.mr-7,
.mx-7 {
  margin-right: 3.5rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 3.5rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 3.5rem !important;
}

.m-8 {
    margin: 4rem !important;
  }
  

.mt-8,
.my-8 {
  margin-top: 4rem !important;
}

.mr-8,
.mx-8 {
  margin-right: 4rem !important;
}

.mb-8,
.my-8 {
  margin-bottom: 4rem !important;
}

.ml-8,
.mx-8 {
  margin-left: 4rem !important;
}

.m-9 {
    margin: 4.5rem !important;
  }
  

.mt-9,
.my-9 {
  margin-top: 4.5rem !important;
}

.mr-9,
.mx-9 {
  margin-right: 4.5rem !important;
}

.mb-9,
.my-9 {
  margin-bottom: 4.5rem !important;
}

.ml-9,
.mx-9 {
  margin-left: 4.5rem !important;
}

.m-10 {
    margin: 5rem !important;
  }
  

.mt-10,
.my-10 {
  margin-top: 5rem !important;
}

.mr-10,
.mx-10 {
  margin-right: 5rem !important;
}

.mb-10,
.my-10 {
  margin-bottom: 5rem !important;
}

.ml-10,
.mx-10 {
  margin-left: 5rem !important;
}

.m-auto {
    margin: auto !important;
  }
  
.mt-auto,
.my-auto {
  margin-top: auto !important;
}
  
.mr-auto,
.mx-auto {
  margin-right: auto !important;
}
  
.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}
  
.ml-auto,
.mx-auto {
  margin-left: auto !important;
}



/* ===================================
   Padding
   p pt pb px py 0 - 10
==================================== */

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.5rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.5rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.5rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.5rem !important;
}

.p-2 {
  padding: 1rem !important;
}

.pt-2,
.py-2 {
  padding-top: 1rem !important;
}

.pr-2,
.px-2 {
  padding-right: 1rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 1rem !important;
}

.pl-2,
.px-2 {
  padding-left: 1rem !important;
}

.p-3 {
  padding: 1.5rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1.5rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1.5rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1.5rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1.5rem !important;
}

.p-4 {
  padding: 2rem !important;
}

.pt-4,
.py-4 {
  padding-top: 2rem !important;
}

.pr-4,
.px-4 {
  padding-right: 2rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 2rem !important;
}

.pl-4,
.px-4 {
  padding-left: 2rem !important;
}

.p-5 {
  padding: 2.5rem !important;
}

.pt-5,
.py-5 {
  padding-top: 2.5rem !important;
}

.pr-5,
.px-5 {
  padding-right: 2.5rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 2.5rem !important;
}

.pl-5,
.px-5 {
  padding-left: 2.5rem !important;
}

.p-6 {
    padding: 3rem !important;
  }
  

.pt-6,
.py-6 {
  padding-top: 3rem !important;
}

.pr-6,
.px-6 {
  padding-right: 3rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 3rem !important;
}

.pl-6,
.px-6 {
  padding-left: 3rem !important;
}

.p-7 {
    padding: 3.5rem !important;
  }
  

.pt-7,
.py-7 {
  padding-top: 3.5rem !important;
}

.pr-7,
.px-7 {
  padding-right: 3.5rem !important;
}

.pb-7,
.py-7 {
  padding-bottom: 3.5rem !important;
}

.pl-7,
.px-7 {
  padding-left: 3.5rem !important;
}

.p-8 {
    padding: 4rem !important;
  }
  

.pt-8,
.py-8 {
  padding-top: 4rem !important;
}

.pr-8,
.px-8 {
  padding-right: 4rem !important;
}

.pb-8,
.py-8 {
  padding-bottom: 4rem !important;
}

.pl-8,
.px-8 {
  padding-left: 4rem !important;
}

.p-9 {
    padding: 4.5rem !important;
  }
  

.pt-9,
.my-9 {
  padding-top: 4.5rem !important;
}

.pr-9,
.px-9 {
  padding-right: 4.5rem !important;
}

.pb-9,
.py-9 {
  padding-bottom: 4.5rem !important;
}

.pl-9,
.px-9 {
  padding-left: 4.5rem !important;
}

.p-10 {
    padding: 5rem !important;
  }
  

.pt-10,
.py-10 {
  padding-top: 5rem !important;
}

.pr-10,
.px-10 {
  padding-right: 5rem !important;
}

.pb-10,
.py-10 {
  padding-bottom: 5rem !important;
}

.pl-10,
.px-10 {
  padding-left: 5rem !important;
}