.group {

    // Header
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }

    // Search
    &__search {
        width: 100%;
        display: flex;
        margin-top: 2rem;

        &-input {
            background-color: #EFEFEF;
            border: 0px;
            border-radius: 3px;
            color: inherit;
            cursor: inherit;
            font-size: 14px;
            min-width: 4rem;
            width: 40%;
            outline: none;
            line-height: 1.42857;
            padding: 8px 12px;
            height: 2.57em;
            margin-right: 2rem;
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

            &::placeholder {
                color: #8e8e8e;
                font-weight: 300;
            }
        }
    }

    // Toggle button
    &__toggle-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: 1px;
        margin-left: 7px;

        span {
            margin-right: 7px;
        }
    }

    // Group list
    &__list {
        margin-top: 4rem;
        display: flex;
        flex-wrap: wrap;

        // Group list item
        &-item {
            width: 50%;
            display: flex;
            padding: 2rem 0;
            color: $dark;

            &--image {
                width: 50px;
                height: 50px;
                background-color: #c3c3c3;
                border-radius: 50%;
                margin-right: 1.5rem;
            }

            &--title {
                font-size: 1.8rem;
            }

            &--subtitle {
                font-size: 1.3rem;
                color: #aeaeae
            }
        }
    }

    // Create group
    &__create {
        min-height: 50rem;
        position: relative;

        // Upload group image
        &-image {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            height: 100px;
            width: 100px;
            border-radius: 50%;
            background-color: #EFEFEF;
            font-size: 12px;
            padding: 1rem;
            margin: 2rem auto;
            margin-bottom: 3rem;
            cursor: pointer;
        }

        // List of selected members
        &-members {
            ul {
                position: absolute;
                top: 50%;
                display: flex;
                flex-wrap: wrap;
                margin-left: 2rem;
                width: 90%;

                li {
                    width: 50%;
                    margin-bottom: 1rem;
                    display: flex;
                    align-items: center;

                    span {
                        opacity: 0;
                        pointer-events: none;
                        margin-left: 5px;
                        transition: 0.2s ease-in-out 0s;
                        cursor: pointer;
                    }

                    &:hover span {
                        opacity: 1;
                        pointer-events: auto;
                    }
                }
            }
        }

        // Bottom submit button
        &-submit {
            position: absolute;
            margin-top: auto;
            width: 100%;
            bottom: 2rem;
        }
    }
}

// Modal messages
.leave-group {
    display: flex;
    flex-direction: column;
    min-height: 30rem;
    justify-content: space-around;
    text-align: center;
    padding: 1rem 2rem;

    &__message {
        line-height: 30px;
    }

    &__buttons {
        display: flex;
        flex-direction: column;

        &>button {
            margin-top: 1.5rem;
        }

        &--light {
            color: $dark;
            border-color: $light-grey;
            background-color: $light-grey;

        }

        &--danger {
            color: $danger;
            border-color: $danger;
        }
    }
}

/* ===================================
   Mobile
==================================== */

@media only screen and (max-width : $mobile) {
    .group {
        padding: 30px 25px;

        // Group list
        &__list-item {
            width: 100%;
            padding: 1rem 0;

            &--image {
                width: 40px;
                height: 40px;
            }

        }

        &__search-input {
            width: auto;
            flex: 2;
        }

        &__toggle-btn {
            margin-left: auto;
        }
    }
}