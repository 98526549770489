/* ===================================
   Regular button
==================================== */
.regular-btn {
    font-family: inherit;
    border: none;
    background-color: $secondary;
    color: white;
    font-weight: 600;
    padding: 8px 15px;
    border-radius: 5px;
    border: 2px solid white;
    cursor: pointer;
    text-align: center;
    letter-spacing: .7px;

    &:active,
    &:focus {
        outline: none;
        border: 2px solid mix(white, $primary, 55%);
    }

    span {
        position: relative;
        top: 1px;
        margin-left: -5px;
    }

}


/* ===================================
   Outline button
==================================== */

.outline-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;

    background-color: transparent;
    border: none;
    color: $primary;
    color: #514F55;
    vertical-align: middle;
    font-size: 1.5rem;
    font-family: Lato;
    border: 1px solid $primary;
    border: 1px solid #514F55;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 5px;
    line-height: 0;
    cursor: pointer;

    span {
        position: relative;
        top: -1px;
    }
}

/* ===================================
   Transparent button
==================================== */
.transparent-btn {
    height: 12px;
    background-color: transparent;
    border: none;
    color: $primary;
    vertical-align: middle;
    font-size: 1.5rem;
    font-family: Lato;

    p {
        display: inline-block;
        font-size: 2rem;
        transform: translateY(2px);
    }

    span {
        position: relative;
        top: -2px;
    }

    &:hover {
        cursor: pointer;
    }
}

@media only screen and (min-width : $desktop) {
    .transparent-btn {
        font-size: 1.5rem;
    }
}

/* ===================================
   Round button
==================================== */
.btn-round {
    font-size: 15px;
    line-height: 18px;
    border-radius: 100px;
    cursor: pointer;
    text-align: center;
    width: auto;
    transition: background-color .25s ease;
    padding: 9px 24px;
    background-color: #fff;
    color: #111;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12);
    display: flex;
    align-items: center;
    justify-content: center;
}