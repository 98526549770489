// Grey tones
$grey-black: #514F55;
$grey-dark: #7C7982;
$grey-medium: #C2BEC4;
$grey-light: #c9c8c8;
$grey-header: #FAFAFA;
$grey-white: #FDFDFD;

$grey: #D4D4D4;
$light-grey: #f2f2f2;
$dark-grey: #9c9c9c;
$dark: #333B3F;


$primary: #3DDAEB;
$secondary: #05B3D7;

$danger: #ED4857;

/* ===================================
   Breakpoints
==================================== */
$breakpoint-sm: 37.5em;      // 600px
$breakpoint-md: 56.25em;     // 900px
$breakpoint-lg: 80em;        // 1200px


// Media Queries
$desktop: 1100px;
$mobile: 480px;
